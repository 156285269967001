import { createResource, onCleanup, onMount } from 'solid-js';
import { checkVersion } from '~/server/checkVersion';

export const useVersion = (onUpdate?: () => void) => {
  const [version, { mutate: setVersion }] = createResource(() => checkVersion());

  const compareVersion = async () => {
    const currentVersion = await checkVersion();

    if (!currentVersion) return;

    const lastVersion = version();

    if (!lastVersion) {
      setVersion(currentVersion);
      return;
    }

    if (lastVersion !== currentVersion) {
      if (onUpdate) return onUpdate();
      window.location.reload();
    }
  };
  onMount(() => {
    const timer = setInterval(
      () => {
        compareVersion();
      },
      import.meta.env.DEV ? 3 * 1000 : 60 * 1000
    );

    onCleanup(() => {
      clearInterval(timer);
    });
  });
};
