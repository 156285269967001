import { Component, JSX, mergeProps, Show } from 'solid-js';
import IconClose from '~/assets/icons/close.svg';
import { CANCEL, CONFIRM } from '~/assets/strings';
import { ButtonProps, ModalButton } from '~/components/common/buttons';
import { useLocalization } from '~/hooks/useLocalization';
import { cn } from '~/utils/classnames';

export interface ConfirmationModalProps {
  onConfirm: () => void;
  onCancel: () => void;
  title: string;
  cover?: JSX.Element;
  message?: string;
  cancelText?: string;
  confirmText?: string;
  okProps?: Partial<ButtonProps>;
  type?: 'info' | 'danger';
  class?: string;
  isConfirmationLoading?: boolean;
  showCancelButton?: boolean;
}

const ConfirmationModal: Component<ConfirmationModalProps> = (rawProps) => {
  const { t } = useLocalization();
  const props = mergeProps(
    { type: 'info', cancelText: t(CANCEL), confirmText: t(CONFIRM), isConfirmationLoading: false, showCancelButton: true },
    rawProps
  );

  return (
    <div class="fixed inset-0 z-1 flex items-center justify-center">
      <div class="fixed inset-0 bg-black/70" onClick={() => props.onCancel()} />
      <div class={cn('absolute flex min-w-[300px] flex-col rounded-lg bg-white text-left shadow-md', props.class)}>
        <div class="flex items-center justify-between border-b border-border-level01 px-8 py-4">
          <span class={cn('font-semibold', { 'text-danger': props.type === 'danger', 'text-text-level01': props.type === 'info' })}>
            {props.title}
          </span>
          <img class="cursor-pointer" src={IconClose} onClick={() => props.onCancel()} />
        </div>
        <div>
          <Show when={props.cover}>{props.cover}</Show>
          <p class="px-8 pt-4 text-sm leading-5 text-text-level02">{props.message}</p>
        </div>
        <div class="flex justify-end gap-2 px-5 py-6">
          <Show when={props.showCancelButton}>
            <ModalButton type="button" onClick={() => props.onCancel()}>
              {props.cancelText}
            </ModalButton>
          </Show>
          <ModalButton
            loading={props.isConfirmationLoading}
            type="button"
            variant="solid"
            color="primary"
            {...props.okProps}
            onClick={() => props.onConfirm()}>
            {props.confirmText}
          </ModalButton>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
