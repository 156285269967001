import { NotFoundError } from '@magicdoor/errors';
import * as Sentry from '@sentry/solid';
import { useNavigate } from '@solidjs/router';
import { createContext, useContext, createSignal, Show, JSX, Component, onMount, onCleanup } from 'solid-js';
import { CLOSE, NO_RENTAL_HISTORY_ERROR, SOMETHING_WENT_WRONG } from '~/assets/strings';
import { NotOnboardedError } from '~/errors/notOnboardedError';
import { NoRentalHistoryError } from '~/errors/validationError';
import { eventEmitter } from '~/framework/eventEmitter';
import { useLocalization } from '~/hooks/useLocalization';

interface ErrorContextType {
  error: () => Error | undefined;
}

const ErrorContext = createContext<ErrorContextType>();

interface ErrorHandlerProps {
  children: JSX.Element;
}

export const ErrorHandler: Component<ErrorHandlerProps> = (props) => {
  const [error, setError] = createSignal<Error | undefined>(undefined);
  const [shouldDisplay, setShouldDisplay] = createSignal<boolean>(true);
  const navigate = useNavigate();
  const { t } = useLocalization();

  const reportError = (error: Error, shouldDisplay?: boolean) => {
    Sentry.captureException(error);
    if (error instanceof NotFoundError || error instanceof NotOnboardedError) {
      navigate('/404');
    } else {
      const display = shouldDisplay || shouldDisplay === undefined;

      setShouldDisplay(display);
      setError(error);
    }
  };

  const clearError = () => {
    setError(undefined);
  };

  onMount(() => {
    eventEmitter.registerForErrors(reportError);
  });

  onCleanup(() => {
    eventEmitter.unregisterFromErrors(reportError);
  });

  const getMessageForError = (error?: Error): string => {
    if (error instanceof NoRentalHistoryError) {
      return NO_RENTAL_HISTORY_ERROR;
    }
    return error?.message || SOMETHING_WENT_WRONG;
  };

  return (
    <ErrorContext.Provider value={{ error }}>
      {props.children}
      <Show when={error() && shouldDisplay()}>
        <div class="fixed inset-0 z-[9999] flex items-center justify-center bg-black/10 p-4">
          <div class="w-[500px] max-w-full overflow-hidden rounded-lg bg-white shadow-lg">
            <div class="border-b border-gray-200 px-6 py-4">
              <h2 class="text-center text-lg font-semibold text-primary-color">{'Oops!'}</h2>
            </div>
            <div class="p-6 py-4">
              <p class="text-center text-sm">{t(getMessageForError(error()))}</p>
            </div>
            <div class="py-4 text-center">
              <button class="rounded bg-primary-color px-4 py-2 font-bold text-white hover:bg-hover-color" onClick={clearError}>
                {t(CLOSE)}
              </button>
            </div>
          </div>
        </div>
      </Show>
    </ErrorContext.Provider>
  );
};

export function useError() {
  const context = useContext(ErrorContext);
  if (!context) {
    throw new Error('useError must be used within an ErrorHandler');
  }
  return context;
}
