import { createSignal, ParentComponent, Show } from 'solid-js';
import { CONFIRMATION, RELOAD, NEW_VERSION_AVAILABLE } from '~/assets/strings';
import ConfirmationModal from '~/components/common/modals/ConfirmationModal';
import { useLocalization } from '~/hooks/useLocalization';
import { useVersion } from '~/hooks/useVersion';

export const VersionChecker: ParentComponent = (props) => {
  const { t } = useLocalization();

  const [showModal, setShowModal] = createSignal(false);

  useVersion(() => setShowModal(true));

  const handleConfirm = () => {
    window.location.reload();
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  return (
    <>
      {props.children}
      <Show when={showModal()}>
        <ConfirmationModal
          title={t(CONFIRMATION)}
          message={t(NEW_VERSION_AVAILABLE)}
          confirmText={t(RELOAD)}
          onConfirm={handleConfirm}
          onCancel={handleCancel}
          showCancelButton={false}
        />
      </Show>
    </>
  );
};
